.input-range__slider {
  appearance: none;
  background: #017eff;
  border: 1px solid #017eff;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.25rem;
  margin-left: -0.5rem;
  margin-top: -0.8rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1.25rem;
}
.input-range__slider:active {
  transform: scale(1.1);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #4f4f4f;
  font-family: 'Roboto';
  font-size: 14px;
  transform: translateZ(0);
  white-space: nowrap;
  font-weight: 400;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: -28;
  top: 1;
  /* font-weight: 600; */
}

.input-range__label--max {
  right: -128;
  top: 1;
  width: 6%;
  /* font-weight: 600; */
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
  color: #828282 !important;
  left: -9;
}

.input-range__label-container {
  left: -15%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 1.188rem;
  cursor: pointer;
  display: block;
  height: 0.6rem;
  width: 338px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 15;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #017eff;
  height: 0.4rem;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.input-range__track .input-range__slider-container:nth-child(3) .input-range__label-container {
  top: 38px;
}
